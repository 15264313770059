import { EntityTypeName, HorizonType as THorizonType } from '@shapeable/types';
import { EntityConfig, entityStandardFormFields } from '@shapeable/core';
const gql = String.raw;

export const HorizonType: EntityConfig<THorizonType> = {
  name: 'HorizonType' as EntityTypeName,
  type: 'taxonomy',
  autoRoutes: ['create', 'update'],
  update: {
    queryFields: gql`
      title 
      subtitle
      years
    `,
  },
  form: {
    fields: {
      ...entityStandardFormFields,
      title: { type: 'text' },
      subtitle: { type: 'text' },
      years: { type: 'int' },
    }
  }
}; 