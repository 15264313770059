import { EntityTypeName, Trend as TTrend } from '@shapeable/types';
import { EntityConfig, entityStandardFormFields } from '@shapeable/core';
import { sortBy } from 'lodash';
const gql = String.raw;

export const Trend: EntityConfig<TTrend> = {
  name: 'Trend' as EntityTypeName,
  type: 'taxonomy',
  canEmbed: true,
  autoRoutes: ['create', 'update', 'list', 'details'],
  sidebar: {
    queryFields: 'outlineNumber',
    // sort: (items) => sortBy(items, 'outlineNumber'),
    // label: (trend) => `${trend.outlineNumber}. ${trend.name}`,
  },
  banner: {
    queryFields: gql`
      description { text }
    `,
    showDescription: true,
  },
  details: {
    queryFields: gql`
      topics { id name slug }
    `,
    children: () => ([
      {
        type: 'linkedMultiple',
        entityTypeName: 'Topic' as EntityTypeName,
        fieldName: 'topics',
      },
    ]),
  },

  list: {
    resultsVariant: "grid",
  },
  update: {
    queryFields: gql`
      outlineNumber
      summary { text }
      intro { text }
      banner { id name image { id url } slug title description alternateText openGraph { id image { id url } } }
      description { text }
      topics { id name slug outlineNumber intro { text } }
    `,
  },
  form: {
    fieldSets: [
      { name: 'overview' },
      { name: 'topics' },
    ],
    fields: {
      ...entityStandardFormFields,
      outlineNumber: { type: 'text', maxWidth: '200px' },
      banner: { type: 'linkedTable', entityTypeName: 'Banner' },
      summary: { type: 'longText' },
      intro: { type: 'longText' },
      radarImage: { label: 'Radar Image', description: 'Add the Radar image asset for this {{TREND}}', type: 'linkedTable', entityTypeName: 'ImageAsset' },
      description: { type: 'longText' },
      topics: { type: 'linkedTable', entityTypeName: 'Topic' as EntityTypeName, fieldSet: 'topics' },
      color: { type: 'linked', entityTypeName: 'Color', description: 'Which accent color applies to this {{TREND}}?'},
    }
  }
}; 