import { EntityTypeName, Topic as TTopic } from '@shapeable/types';
import { EntityConfig, entityStandardFormFields, entityTableColumn, entityTableContentColumn, entityTableNameColumn } from '@shapeable/core';
import { sortBy } from 'lodash';
const gql = String.raw;

export const Topic: EntityConfig<TTopic> = {
  name: 'Topic' as EntityTypeName,
  type: 'taxonomy',
  canEmbed: true,
  autoRoutes: ['create', 'update', 'list', 'details'],
  sidebar: {
    queryFields: 'outlineNumber',
//    sort: (items) => sortBy(items, 'outlineNumber'),
//    label: (topic) => `${topic.outlineNumber}. ${topic.name}`,
  },
  table: {
    fieldNames: ["name", "outlineNumber", "intro"],
  },
  details: {
    queryFields: gql`
      subTopics { id name slug }
      people { id slug }
    `,
    children: () => ([
      {
        type: 'linkedMultiple',
        entityTypeName: 'SubTopic' as EntityTypeName,
        fieldName: 'subTopics',
      },
      {
        type: 'linkedMultiple',
        entityTypeName: 'Person',
        fieldName: 'moderators',
        label: 'Moderators',
      },
      {
        type: 'linkedMultiple',
        entityTypeName: 'Person',
        fieldName: 'people',
      },
      {
        type: 'linkedMultiple',
        entityTypeName: 'FeedEntry',
        fieldName: 'feedEntries',
      },
    ]),
  },
  banner: {
    queryFields: gql`
      intro { text }
      description { text }
    `,
    descriptionField: 'intro',
    showDescription: true,
  },
  list: {
    resultsVariant: "grid",
  },
  update: {
    valueKeys: (fieldName) => {
      if (fieldName === 'video') {
        return ['id', 'url', 'text'];
      }
      
      return ['url', 'id', 'text'];
    },
    queryFields: gql`
      trendIntro { text }
      summary { text }
      intro { text }
      description { text }
      outlineNumber
      feedEntries { id name slug openGraph { id title description { id text plain plainTruncated: plain(truncate: { words: 25 }) } date url author image { id url } } }
      moderators { id name type slug position isMember hasAccount organisation { id name slug location { id name slug state { id name abbr } openGraph { id image { id url } } country { id name slug shorthand } } } openGraph { id image { id url } } photo { id url thumbnails { full { url width height } } } location { id name slug state { id name abbr } openGraph { id image { id url } } country { id name slug shorthand } } }
      people { id name type slug position isMember hasAccount organisation { id name slug location { id name slug state { id name abbr } openGraph { id image { id url } } country { id name slug shorthand } } } openGraph { id image { id url } } photo { id url thumbnails { full { url width height } } } location { id name slug state { id name abbr } openGraph { id image { id url } } country { id name slug shorthand } } }
      subTopics { id name slug outlineNumber intro { text } }
      video { id name url thumbnail { id url thumbnails { full { url width height } } } description { id text } }
      banner { id name image { id url } slug title description alternateText openGraph { id image { id url } } }
    `,
  },
  form: {
    fieldSets: [
      { name: 'overview' },
      { name: 'properties' },
      { name: 'people' },
      { name: 'media' },
      { name: 'articles' },
      { name: 'sub-topics' },
    ],
    fields: {
      ...entityStandardFormFields,
      outlineNumber: { type: 'text', fieldSet: 'properties', maxWidth: '200px' },
      banner: { type: 'linkedTable', fieldSet: 'media', entityTypeName: 'Banner' },
      summary: { type: 'longText', fieldSet: 'properties' },
      intro: { type: 'longText' },
      description: { type: 'longText' },
      trendIntro: { type: 'longText', description: 'Provide a contextual introduction for this {{Topic.label}} when viewed from the {{Trend.label}} page above it' },
      subTopics: { type: 'linkedTable', entityTypeName: 'SubTopic' as EntityTypeName, fieldSet: 'sub-topics' },
      video: { type: 'linkedTable', fieldSet: 'media', isMulti: false, entityTypeName: 'Video' },
      moderators: { label: 'Moderators', fieldSet: 'people', description: 'Who are the moderators for this {{TOPIC}}?', type: 'linkedMultiple', entityTypeName: 'Person' },
      people: { label: 'Global Experts', fieldSet: 'people', description: 'Who are the global experts for this {{TOPIC}}?', type: 'linkedMultiple', entityTypeName: 'Person' },
      feedEntries: { type: 'linkedTable', fieldSet: 'articles', entityTypeName: 'FeedEntry' },
      chartImage: { label: 'Chart Image', fieldSet: 'media', description: 'Setup an image asset for the anticipation potential chart for this {{TOPIC}}', type: 'linkedTable', entityTypeName: 'ImageAsset' },
      anticipatoryImpact: { label: 'Anticipatory Impact', type: 'longText' },
      anticipatoryImpactImage: { label: 'Anticipatory Impact Image', fieldSet: 'media', description: 'Setup an image asset for the anticipatory impact radar chart for this {{TOPIC}}', type: 'linkedTable', entityTypeName: 'ImageAsset' },
    },
  }

}; 