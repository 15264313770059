import { SubTopic as TSubTopic, EntityTypeName } from "@shapeable/types";
import { EntityConfig, entityStandardFormFields, entityTableColumn, entityTableContentColumn, entityTableCreatedByColumn, entityTableEntityLinkColumn, entityTableNameColumn } from '@shapeable/core';
import { sortBy } from 'lodash';
const gql = String.raw;

export const SubTopic: EntityConfig<TSubTopic> = {
  name: 'SubTopic' as EntityTypeName,
  type: 'taxonomy',
  canEmbed: true,
  autoRoutes: ['create', 'update', 'list', 'details'],
  sidebar: {
    queryFields: 'outlineNumber',
    // sort: (items) => sortBy(items, 'outlineNumber'),
    // label: (subTopic) => `${subTopic.outlineNumber}. ${subTopic.name}`,
  },
  table: {
    fieldNames: ["name", "outlineNumber", "intro"],
  },
  details: {
    queryFields: gql`
      openGraph { id title description { id text plain plainTruncated: plain(truncate: { words: 25 }) } date url author image { id url } }
      banner {  id name image { id url } slug title description alternateText openGraph { id image { id url } } }
      topic { id name slug }
    `,
  },
  banner: {
    queryFields: gql`
      intro { text }
      description { text }
    `,
    descriptionField: 'intro',
    showDescription: true,
  },
  list: {
    resultsVariant: "grid",
    queryFields: gql`
      banner { id name image { id url } slug title description alternateText openGraph { id image { id url } } }
      openGraph { id title description { id text plain plainTruncated: plain(truncate: { words: 25 }) } date url author image { id url } }
      intro { text }
      description { text plain(truncate: { words: 45 }) }
    `,
  },
  update: {
    queryFields: gql`
      banner { id name image { id url } slug title description alternateText openGraph { id image { id url } } }
      people { id name type slug position isMember hasAccount organisation { id name slug location { id name slug state { id name abbr } openGraph { id image { id url } } country { id name slug shorthand } } } openGraph { id image { id url } } photo { id url thumbnails { full { url width height } } } location { id name slug state { id name abbr } openGraph { id image { id url } } country { id name slug shorthand } } }
      feedEntries { id name slug }
      outlineNumber
      horizons { id name slug type { id name slug } intro { text } description { text } }
      summary { text }
      intro { text }
      description { text }
    `,
  },
  form: {
    fieldSets: [
      { name: 'overview' },
      { name: 'horizons' },
      { name: 'anticipation-scores' },
      { name: 'connections' },
    ],
    fields: {
      ...entityStandardFormFields,
      banner: { type: 'banner' },
      outlineNumber: { type: 'text', maxWidth: '200px' },
      subTopic: { type: 'longText' },
      summary: { type: 'longText' },
      intro: { type: 'longText' },
      horizons: { type: 'linkedTable', entityTypeName: 'Horizon' , fieldSet: 'horizons' },
      description: { type: 'longText' },
      anticipationScores: { type: 'longText', fieldSet: 'anticipation-scores' },
      anticipationScoresImage: { type: 'linkedTable', entityTypeName: 'ImageAsset', fieldSet: 'anticipation-scores' },
      people: { type: 'linkedMultiple', entityTypeName: 'Person', fieldSet: 'connections' },
      feedEntries: { type: 'linkedMultiple', entityTypeName: 'FeedEntry', fieldSet: 'connections' },
    }
  }
}; 