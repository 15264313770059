import * as React from 'react';
import { filter, concat, set, includes, pick, clone, cloneDeep } from 'lodash';
import { createApplication, WorkstreamTrafficControl, entityConfigs, appSectionConfigs as coreAppSectionConfigs, Icons } from '@shapeable/core';
import { ApplicationLogo, ApplicationLogoInverted } from './components';
import styled from 'styled-components';
import { themes } from '@shapeable/theme';

const gql = String.raw;

import { Topic, Trend, SubTopic, Horizon, HorizonType } from './config';
import { EntityTypeName } from '@shapeable/types';
const { Person, FeedEntry, Link, Page } = entityConfigs;

const theme = themes.TRIBE_THEME;

const appSectionConfigs = coreAppSectionConfigs;

Page.form.fields.trends = {
  type: 'linkedMultiple',
  entityTypeName: 'Trend' ,
  fieldSet: 'connections',
  recommended: false,
};

Person.form.fields.hideOnSite = {
  type: 'boolean',
  fieldSet: 'visibility',
  recommended: false,
};

FeedEntry.form.fields.topics = {
  type: 'linkedMultiple',
  fieldSet: 'taxonomies',
  entityTypeName: 'Topic' ,
};

FeedEntry.form.fields.subTopics = {
  type: 'linkedMultiple',
  fieldSet: 'taxonomies',
  entityTypeName: 'SubTopic' ,
};


FeedEntry.filters.fields = {
  topic: { entityTypeName: 'Topic'  },
  subTopic: { entityTypeName: 'SubTopic'  },
};

Link.form.fields.author = {
  type: 'text',
};

Link.form.fields.publication = {
  type: 'text',
};

Link.form.fields.edition = {
  type: 'text',
};

Link.form.fields.accessDate = {
  type: 'date',
};

Link.form.fields.footnoteNumber = {
  type: 'int',
};

Link.form.fields.volume = {
  type: 'int',
};

Link.form.fields.year = {
  type: 'int',
};

Link.form.fields.referencePages = {
  type: 'text',
};

delete FeedEntry.form.fields.challenge;
delete FeedEntry.form.fields.offeringType;
delete FeedEntry.form.fields.keyIssues;

const { platform } = appSectionConfigs;

// reduce appSection configs
appSectionConfigs.insightsData.label = 'Insights';
appSectionConfigs.insightsData.children = filter(appSectionConfigs.insightsData.children, (child: any) => includes(["insights", "citations"], child.id));

appSectionConfigs.trendsTopics = {
  id: 'trends-topics',
  label: 'Trends & Topics',
  children: [
    {
      id: 'trends',
      type: 'list-expandable',
      entityTypeName: 'Trend',
    },
    {
      id: 'topics',
      type: 'list-expandable',
      entityTypeName: 'Topic',
    },
    {
      id: 'sub-topics',
      type: 'list-expandable',
      entityTypeName: 'SubTopic',
    },
  ]
};



// appSectionConfigs.action.children = filter(appSectionConfigs.action.children, (child: any) => includes(["impactStories"], child.id));
appSectionConfigs.stakeholders.children = filter(appSectionConfigs.stakeholders.children, (child: any) => !includes(["chapters"], child.id));

// move things into Platform Admin

platform.children.push({
  id: 'platform/challenges-goals',
  type: 'list-group',
  label: 'Motivations & Goals',
  entities: ['Challenge', 'Sdg', ],
});

platform.children.push({
  id: 'platform/places',
  type: 'list-group',
  label: 'Places',
  entities: ['Economy', 'Location'],
});

platform.children.push({
  id: 'platform/groups-data',
  type: 'list-group',
  label: 'Groups & Data',
  entities: ['Team', 'Chapter', 'List'],
});

delete appSectionConfigs.action;
delete appSectionConfigs.teams;
// delete appSectionConfigs.following;
delete appSectionConfigs.challengesGoals;
// delete appSectionConfigs.places;

const myEntityConfigs = { 
  ...entityConfigs,
  Horizon,
  HorizonType,
  SubTopic,
  Topic,
  Trend,
};

export const application = createApplication({
  theme: themes.TRIBE_THEME,
  entityConfigs: myEntityConfigs,
  appSectionConfigs,
  options: {
    systemsMapping: 'full',
    shapeLogoLabel: 'oceans',
    shapeLogoIconColor: 'primary',
    shapeLogoIcon: Icons.Platforms.Ecosystems,
  },
  components: {
    Logo: ApplicationLogo,
    LogoInverted: ApplicationLogoInverted,
  },
});


/* ---- Patch Entities definitions afterwards to ensure sub-props are already set --- */

const entities = application.entities;

// -- ensure that traffic control team is not shown in lists
entities.Team.list.filterItems = (items) => filter(items, item => item.slug !== 'traffic-control');

// -- show Traffic controllers
entities.Team.list.postBanner = <WorkstreamTrafficControl />;
