import { Horizon as THorizon, EntityTypeName } from '@shapeable/types';
import { EntityConfig, entityStandardFormFields, entityTableContentColumn, entityTableLinkedColumn } from '@shapeable/core';
const gql = String.raw;

export const Horizon: EntityConfig<THorizon> = {
  name: 'Horizon' as EntityTypeName,
  type: 'taxonomy',
  table: {
    fieldNames: ["type", "intro", "description"],
  },
  autoRoutes: ['create', 'update'],
  update: {
    queryFields: gql`
      intro { text }
      description { text }
      type { id name slug }
    `,
  },
  form: {
    fields: {
      ...entityStandardFormFields,
      intro: { type: 'longText' },
      description: { type: 'longText' },
      type: { type: 'linked', entityTypeName: 'HorizonType'  },
   }
  }
}; 